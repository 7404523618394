<template>
  <div>
    <div class="member_lesson_infor">
      <div class="member_lesson_tip_container">
        <div class="member_lesson_tip">
          <!-- <img src="../../assets/image/time_icon.jpg" alt /> -->
          {{ timeClock }}
        </div>
        <div class="member_lesson_tip">
          即將上課：&nbsp;{{ lastTimeFilter(1, readyClass.classDate) }}&nbsp;{{
            lastTimeFilter(2, readyClass.classTime)
          }}{{ lastTimeFilter(3, readyClass.classTime) }}&nbsp;{{
            readyClass.classTitle
          }}
        </div>
      </div>
      <ul class="tabs">
        <li
          class="tab_li"
          v-for="info in messageTitles"
          :class="{ active: messageTableId == info.type }"
          :key="info.type"
          @click="showTable(info.type)"
        >
          {{ info.name }}
        </li>
      </ul>
      <div class="member_lesson_infor_container">
        <!-- 我的課表 -->
        <div id="infor1" v-if="messageTableId == 'class'">
          <class-order-table
            :studentClassData="classOrderData"
            :studentDetail="daysClassDetail"
            @getClassOrder="getClassOrderList"
            @setClassDetailData="setClassDetail"
            @setDefaultClassOrder="setClassOrderArr"
            @showDialog="changeDialogId"
            @setTeaOrder="setOrderFromChild"
            @submitLoad="uplaodHomeworkAnswer"
            :classId="1"
          ></class-order-table>
        </div>
        <!--课表canlender end-->
        <!-- 基本資料 -->
        <!-- 按下修改資料後 input拿掉readonly class換成input_unlock -->
        <!-- 按下儲存資料後 input增加readonly class換成input_lock -->
        <div id="infor2" v-if="messageTableId == 'info'">
          <div class="member_lesson_infor_title">基本資料</div>
          <div class="personal_data_container">
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">學生姓名</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  v-model="studentInfo.studentName"
                  type="text"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">學生性別</div>
              <div class="personal_data_input">
                <select
                  v-if="isUpdateInfo"
                  v-model="studentInfo.gender"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                >
                  <option value="男">男生</option>
                  <option value="女">女生</option>
                  <option value="不詳">不詳</option>
                </select>
                <input
                  v-if="!isUpdateInfo"
                  class="input_lock"
                  readonly
                  v-model="studentInfo.gender"
                  type="text"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">學生年級</div>
              <div class="personal_data_input">
                <select
                  v-if="isUpdateInfo"
                  v-model="studentInfo.grade"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                >
                  <option v-for="(k, i) in hsdGradeList" :key="i" :value="k">
                    {{ k }}
                  </option>
                </select>
                <input
                  v-if="!isUpdateInfo"
                  class="input_lock"
                  readonly
                  v-model="studentInfo.grade"
                  type="text"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">就讀學校</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.school"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">身分字號</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.idCardNumber"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">手機號碼</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.cellPhone"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">住家電話</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.homePhone"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container" style="min-width: 90%">
              <div class="personal_data_input_title">聯絡地址</div>
              <div class="personal_data_input adderFlex">
                <!-- <div></div><div></div><div></div> -->
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  class="stu_input_city"
                  v-model="studentInfo.city"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
                <span class="address_title"></span>
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  class="stu_input_city"
                  v-model="studentInfo.town"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
                <span class="address_title">區</span>
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  class="stu_input_adder"
                  v-model="studentInfo.contactAddress"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">電子信箱</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.email"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div style="clear: both"></div>
            <div class="personal_data_infor_title">學生聯絡人資料</div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">姓名</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.contactName"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">學生關係</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.interrelation"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">手機號碼</div>
              <div class="personal_data_input">
                <input
                  :readonly="!isUpdateInfo"
                  type="text"
                  v-model="studentInfo.contactMobile"
                  :class="{
                    input_lock: !isUpdateInfo,
                    input_unlock: isUpdateInfo
                  }"
                />
              </div>
            </div>
            <div style="clear: both"></div>
            <div
              class="personal_data_btn"
              v-if="!isUpdateInfo"
              @click="isUpdateInfo = true"
            >
              修改資料
            </div>
            <div
              class="personal_data_btn"
              v-if="isUpdateInfo"
              @click="saveUserInfo"
            >
              儲存資料
            </div>
          </div>
        </div>
        <!-- 基本資料end -->
        <!-- 成績紀錄 -->
        <div id="infor3" v-if="messageTableId == 'academic'">
          <div class="member_lesson_infor_title">成績紀錄</div>

          <div class="score_record_search_container">
            <div class="score_record_search">
              <el-date-picker
                type="month"
                value-format="yyyy-MM"
                v-model="academicForm.logMonth"
                @change="getStudentAcademic"
                placeholder="選擇月份"
              />
            </div>
          </div>
          <div class="score_record_contianer" style="margin-top: 15px">
            <el-table
              @sort-change="sortDateScore"
              :data="studentScoreData"
              empty-text="暫無數據"
              style="width: 100%"
            >
              <el-table-column
                prop="classDate"
                label="日期"
                sortable="custom"
              ></el-table-column>
              <el-table-column
                prop="curriculum"
                label="科目"
                sortable="custom"
              ></el-table-column>
              <el-table-column
                prop="academicRecord"
                label="分數"
                sortable="custom"
              ></el-table-column>
              <el-table-column prop="classTitle" label="備註"></el-table-column>
            </el-table>
          </div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="academicForm.pageNum"
              :page-sizes="pageList"
              :page-size="academicForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="academicCounts"
            ></el-pagination>
          </div>
        </div>
        <!-- 成績紀錄end -->
        <!-- 上課錄影 -->
        <div id="infor4" v-if="messageTableId == 'vedio'">
          <div class="member_lesson_infor_title">上課錄影</div>
          <div class="video_record_tip">提醒同學，上課錄影僅保留7天喔</div>
          <div class="video_record_title_contianer">
            <div class="video_record_title_name">上課時間內容</div>
            <div class="video_record_title_download">錄影下載</div>
          </div>
          <div class="video_record_main">
            <div v-if="playbackList && playbackList.length">
              <div
                class="video_record_contianer"
                v-for="(item, i) in playbackList"
                :key="i"
              >
                <div class="video_record_name">
                  {{ item.classDate }}&nbsp;{{ item.classTitle }}
                </div>
                <div class="video_record_download">
                  <a :href="item.playbackUrl">
                    <img src="../../assets/image/download_icon.png" alt />
                  </a>
                </div>
              </div>
            </div>
            <div class="noDataTip" v-else>暫無數據</div>
          </div>

          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="sizeChange"
              @current-change="currentChange"
              :current-page="playForm.pageNum"
              :page-sizes="pageList"
              :page-size="playForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="playForm.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 上課錄影end -->
        <!-- 練功記錄 -->
        <div id="infor5" v-if="messageTableId == 'time'">
          <div class="member_lesson_infor_title">學習歷程</div>
          <div class="test_again_cont">
            <div>
              <div class="form_input_title">測試科目</div>
              <div class="form_input" style="width: 150px; margin-right: 40px">
                <select v-model="subject_id" @change="changeCurriculum">
                  <option value="">全部</option>
                  <option
                    v-for="(culum, i) in curriculumList"
                    :key="i"
                    :value="culum"
                  >
                    {{ culum }}
                  </option>
                </select>
              </div>
            </div>
            <el-button type="warning" @click="testWrongAgain"
              >我的錯題再練</el-button
            >
          </div>
          <div id="myTestChart" :class="{'test_chart_cont': userTestLogLegend.length > 0 }"></div>
          <!-- <div v-if="!userTestLogLegend.length">暫無數據</div> -->
          <div v-if="userTestList && userTestList.length">
            <div class="score_record_contianer" style="margin-top: 15px">
              <el-table
                :data="userTestList"
                empty-text="暫無數據"
                style="width: 100%"
              >
                <el-table-column
                  prop="test_name"
                  label="試卷名"
                ></el-table-column>
                <el-table-column
                  prop="question_amount"
                  label="測試題數"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  prop="test_score"
                  label="測試得分"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  prop="right_amount"
                  label="正確題數"
                  sortable="custom"
                ></el-table-column>
                <el-table-column
                  prop="wrong_amount"
                  label="錯誤題數"
                  sortable="custom"
                ></el-table-column>
                <el-table-column label="弱點分析">
                  <template slot-scope="scope">
                    <div
                      class="weakness"
                      @click="showWeakness(scope.row.weakness_analysis)"
                    >
                      {{ scope.row.weakness_analysis }}
                    </div>
                  </template></el-table-column
                >
              </el-table>
            </div>
          </div>
          <div class="noDataTip" v-else>暫無數據</div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="sizeChange"
              @current-change="currentChange"
              :current-page="testForm.pageNum"
              :page-sizes="pageList"
              :page-size="testForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="testForm.counts"
            ></el-pagination>
          </div>
        </div>
        <!-- 練功記錄end -->
      </div>
    </div>

    <!-- ... pup視窗-弱点分析 ... -->
    <div class="member_pup_class_detail" v-if="tableClassId == 3">
      <div class="member_pup_bg" @click="tableClassId = 0"></div>
      <div class="member_pup_container">
        <div class="member_pup_main" style="padding: 40px 25px">
          {{ weaknessDetial }}
        </div>
        <img
          alt
          class="member_pup_close"
          @click="tableClassId = 0"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-弱点分析end ... -->
    <!-- ... pup視窗-給予評分 ... -->
    <div class="pup_container_report_star" v-if="tableClassId == 2">
      <div class="pup_form_bg" @click="tableClassId = 0"></div>
      <div class="pup_form_container">
        <div class="report_star_title">上課評分</div>
        <div class="report_star_container">
          <select v-model="stuEffect.effect">
            <!-- <option value>選擇評分星數</option> -->
            <option v-for="g in 5" :key="g" :value="g">{{ g }}顆星</option>
          </select>
        </div>
        <div class="report_class_tip">送出後將無法修改</div>

        <img
          alt
          class="close_icon"
          @click="tableClassId = 0"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn" @click="sendEffects">送出評分</div>
      </div>
    </div>
    <!-- ... pup視窗-給予評分end ... -->
    <!-- ... pup視窗-下载作业列表 ... -->
    <div class="member_pup_class_detail" v-if="tableClassId == 6 || tableClassId == 7">
      <div class="member_pup_bg" @click="changeDialogId(0)"></div>
      <div class="member_pup_container" style="top: 10vh;">
        <div>
          <div class="member_pup_main">
            <a class="subjectUrl" v-for="work in homeworkList" :key="work.id" @click="downloadWorks(work,tableClassId == 6 ? 'subject' : 'answer')">
              <span class="subjectName">{{tableClassId == 6 ? work.SubjectFileName: work.AnswerFileName}}</span>
              <span><i class="el-icon-download" /></span>
            </a>
          </div>
          <img
            src="../../assets/image/close_icon.png"
            @click="changeDialogId(0)"
            class="member_pup_close"
            alt
          />
        </div>
      </div>
    </div>
    <!-- ... pup視窗-下载作业列表end ... -->
  </div>
</template>
<script>
import {
  getSUserInfo,
  getHsdUserPhoto,
  getHsdLoginOut,
  getUpdateUserInfo,
  getStuOrderList,
  getOrderEditEffect,
  getAcademicList,
  getUrlList,
  getLastClassOrder,
  getTestListByUser,
  getUserWrongTestAgain,
  getUserClassOrderNew,
  getStuUplaodAnswer,
  getLearnPlanWork,
  getLearnPlanAnswer,
  getHsdCulum,
  getUserLearnTestLog,
} from "../../api/api";
import { Message } from "element-ui";
import { mapState, mapMutations } from "vuex";
import classOrderTable from "./class_order_table";
import { hsdApi } from "@/api/baseApi";
export default {
  props: { userId: "" },
  data() {
    return {
      selectsMonth: "",
      timeClock: "",
      messageTableId: "class",
      tableClassId: 0,
      startHour: 0,
      timeInterVal: null,
      readyClass: {},
      classstatus: { 0: "已取消", 1: "預約中", 2: "準備中", 3: "已完成" },
      pageList: [10, 15, 20],
      currentDate: [],
      studentScoreData: [],
      formate: { date: false, type: false, km: false, score: false },
      formatter: [
        { id: "AcademicRecord", name: "選擇成績排序" },
        { id: "Curriculum", name: "選擇科目排序" },
      ],
      sortTitle: "",
      isUpdateInfo: false,
      fileList: [],
      currDialogId: null,
      memberSetting: false, //账号设定
      classDetailNews: false, //上课详细咨询
      reportStar: false, //给予评分
      // latestnewsDetail: false,
      studentInfo: {},
      userAvatar: "",
      topNavDialogId: 0,
      messageTitles: [
        { type: "time", name: "學習歷程" },
        { type: "vedio", name: "上課錄影" },
        { type: "info", name: "基本資料" },
        { type: "class", name: "我的課表" },
      ],
      classOrderData: [],
      daysClassDetail: {},
      classDetailList: {},
      stuEffect: {},
      academicForm: { pageNum: 1, pageSize: 10 },
      playForm: { pageNum: 1, pageSize: 10 },
      testForm: { pageNum: 1, pageSize: 10 },
      academicCounts: 0,
      playbackList: [],
      userTestList: [],
      sortOpts: "",
      subject_id: "",
      curriculumList: [],
      uesrListEchars: [], //学习历程
      userTestLogLegend: [],
      userEchartList: [],
      userTestLogMap: {
        AfterTest: "課前測驗",
        BeforeTest: "課後測驗",
        Homework: "作業成績",
        UserTest: "自測",
        Section: "段考",
        Weekly: "周考",
      },
      weaknessDetial: "",
      homeworkList: [],
    };
  },
  components: { classOrderTable },
  watch: {},
  computed: {
    ...mapState(["hsdGradeList"]),
  },
  created() {
    this.getUserInfo();
    let date = new Date();
    let y = date.getFullYear(),
        intM = date.getMonth(),
        m = intM + 1,
        d = date.getDate();
    this.getClassOrderList(y, m, d);
    this.getStudentAcademic();
    this.getPlayUrlList();
    this.getLastClass();
    this.getTestLog();
  },
  methods: {
    ...mapMutations(["setLocalTestPaper"]),
    //弱点分析
    showWeakness(v) {
      this.tableClassId = 3;
      this.weaknessDetial = v;
    },
    async testWrongAgain() {
      const res = await getUserWrongTestAgain({ subject_id: this.subject_id });
      if (res && res.success == 1) {
        this.setLocalTestPaper(res.data);
        let newWindow = window.open("_blank");
        newWindow.location = "/startTest/index.html";
        // location.href = "/startTest/index.html";
      }
    },
    //上傳作業答題
    async uplaodHomeworkAnswer({ fileData, learnPlanId }) {
      if (fileData) {
        let formData = new FormData();
        formData.append("learnPlanId", learnPlanId);
        for(let i in fileData){
          formData.append("fileData", fileData[i].raw);
        }
        const res = await getStuUplaodAnswer(formData);
        if (res && res.success == 1) {
          Message({
            type: "success",
            message: "上傳成功",
          });
        }
      }
    },
    //下载作业
    downloadWorks(work, type) {
      let href =
        type == "subject"
          ? "/api/v1/learn_plan/get_subject"
          : "/api/v1/learn_plan/get_answer";
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.style.display = "none";
      link.href = `${hsdApi}${href}?id=${work.Id}`;
      link.click();
      // event.preventDefault();
      return false;
    },
    //即將開課
    async getLastClass() {
      const res = await getLastClassOrder({ ...this.testForm });
      if (res && res.success == 1) {
        this.readyClass = res.data;
      }
    },
    //测试成绩记录
    async getTestList() {
      const res = await getTestListByUser({ user_id: this.userId });
      if (res && res.success == 1) {
        this.userTestList = res.data.data;
      }
    },
    //成績排序
    sortDateScore(sort) {
      //return console.log(sort)
      let order = "";
      if (sort.prop == "classDate") {
        order = "ScheduleDate";
      } else if (sort.prop == "curriculum") {
        order = "Curriculum";
      } else {
        order = "AcademicRecord";
      }
      if (sort.order == "descending") {
        order = `-${order}`;
      }
      this.academicForm.sort = order;
      this.getStudentAcademic();
    },
    handleSizeChange(val) {
      this.academicForm.pageSize = val;
      this.getStudentAcademic();
    },
    handleCurrentChange() {
      this.academicForm.pageNum = val;
      this.getStudentAcademic();
    },
    sizeChange(val) {
      this.playForm.pageSize = val;
      this.getPlayUrlList();
    },
    currentChange(val) {
      this.playForm.pageNum = val;
      this.getPlayUrlList();
    },
    //课程 日历接口
    async getClassOrderList(y, m, d) {
      let logMonth = m < 10 ? `${y}-0${m}` : `${y}-${m}`;
      const res = await getUserClassOrderNew({ logMonth });
      if (res && res.code == 200 && res.success == 1) {
        this.setClassOrderArr(res.data.Data, d);
      }
    },
    setClassDetail(d) {
      this.daysClassDetail = this.classOrderData[d]
        ? this.classOrderData[d]
        : [];
    },
    setOrderFromChild(item) {
      this.classDetailList = item;
    },
    setClassOrderArr(arr, d) {
      this.classOrderData = [];
      for (let i in arr) {
        this.classOrderData.push(arr[i].learnPlanList);
      }
      this.setClassDetail(d);
    },
    //控制彈窗
    changeDialogId(k, d) {
      this.tableClassId = k;
      if(k == 6 || k == 7){
        this.homeworkList = d
      }
    },
    //學生成績列表
    async getStudentAcademic() {
      const res = await getAcademicList(this.academicForm);
      if (res && res.success == 1) {
        this.studentScoreData = res.data.data;
        this.academicCounts = res.data.count;
      }
    },
    //學生上課影音列表
    async getPlayUrlList() {
      const res = await getUrlList(this.playForm);
      if (res && res.success == 1) {
        this.playbackList = res.data.data;
        this.playForm.counts = res.data.count;
      }
    },
    //學習歷程
    async getTestLog(curriculum = "") {
      const res = await getUserLearnTestLog({ curriculum: curriculum });
      if (res && res.success == 1) {
        this.userEchartList = res.data;
        this.changeDataToEhars(res.data);
      }
    },
    //調整數據適用圖表
    changeDataToEhars(data) {
      this.userTestLogLegend = [];
      this.uesrListEchars = [];
      for (let i in data) {
        if (data[i]) {
          this.userTestLogLegend.push(this.userTestLogMap[i]);
          let recordList = [];
          data[i].forEach((elem) => {
            recordList.push(elem.AcademicRecord);
          });
          let userSeries = {
            name: this.userTestLogMap[i],
            type: "line",
            data: recordList,
          };
          this.uesrListEchars.push(userSeries);
        }
      }
      console.log(this.uesrListEchars);
    },
    //学习历程选择科目
    changeCurriculum(e) {
      let culum = e.target.value;
      this.getTestLog(culum);
      this.drawTestLine();
    },
    //获取用户基本信息
    async getUserInfo() {
      const res = await getSUserInfo();
      if (res && res.success == 1) {
        this.studentInfo = res.data;
        this.getCulumList(res.data.grade);
      }
    },
    //获取學生年級所有科目
    async getCulumList() {
      const res = await getHsdCulum();
      if (res && res.success == 1) {
        this.curriculumList = res.data;
      }
    },

    //保存用户信息
    async saveUserInfo() {
      const res = await getUpdateUserInfo(this.studentInfo);
      if (res && res.code == 200 && res.success === 1) {
        this.isUpdateInfo = false;
        Message({ type: "success", message: "學生基本資料修改成功" });
      }
    },
    //上传用户头像
    sendUserAvatar(id) {
      this.currDialogId = id;
      this.memberSetting = false;
    },
    //打开修改密码弹窗
    updatePassword() {
      this.currDialogId = 1;
      this.memberSetting = false;
    },
    //輸出評分
    async sendEffects() {
      this.stuEffect.OrderId = this.classDetailList.classOrderId;
      const res = await getOrderEditEffect(this.stuEffect);
      if (res && res.code == 200 && res.success == 1) {
        this.tableClassId = 0;
        Message({
          type: "success",
          message: "評價成功",
        });
      }
    },
    //我的课表切换
    showTable(id) {
      this.messageTableId = id;
      let _this = this;
      if (id == "time") {
        this.getTestList();
        this.$nextTick(() => {
          _this.drawTestLine();
        });
      }
    },
    drawTestLine() {
      let that = this,
        xData = [];
      if (!this.userTestLogLegend.length) return;
      getXData(this.userTestLogLegend[0]);
      let myChart = this.$echarts.init(document.getElementById("myTestChart"));
      // console.log(myChart);
      myChart.setOption({
        title: {
          text: "練功記錄折綫圖",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.userTestLogLegend,
          selectedMode: "single",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: xData,
        },
        yAxis: {
          type: "value",
        },
        series: [...this.uesrListEchars],
      });

      myChart.on("legendselectchanged", function (obj) {
        var name = obj.name;
        var option = myChart.getOption();
        getXData(name);
        option.xAxis[0].data = xData;
        myChart.setOption(option, true);
      });
      function getXData(name) {
        xData = [];
        let findKey = (value, compare = (a, b) => a === b) => {
          return Object.keys(that.userTestLogMap).find((k) =>
            compare(that.userTestLogMap[k], value)
          );
        };
        let legendKey = findKey(name);
        let currData = that.userEchartList[legendKey];
        currData.forEach((elem) => {
          xData.push(elem.ScheduleDate);
        });
        xData = xData.reverse();
        console.log(xData);
      }
    },
    showNewsDialog(id) {
      this.topNavDialogId = id;
    },
    closeDialog() {
      this.topNavDialogId = 0;
    },
    //上传图片
    async updateAvatar(dl) {
      try {
        const res = await getHsdUserPhoto(dl);
        if (res && res.code == 200) {
          this.currDialogId = null;
          const h = this.$createElement;
          Message({
            message: h("i", { style: 'color: "#000000"' }, "用戶圖像上傳成功"),
          });
          this.getUserInfo();
        }
      } catch (err) {}
    },
    //登出
    async loginOut() {
      this.memberSetting = false;
      const res = await getHsdLoginOut();
      if (res && res.code == 200) {
        this.$router.push("/login");
      }
    },
    //确认支付
    surePayFor() {
      let routeUrl = this.$router.resolve({
        path: "/payChecking",
        query: { id: 96 },
      });
      window.open(routeUrl.href, "_blank");
    },
    setTimeVal() {
      let date = new Date();
      let d = date.toLocaleDateString(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      this.timeClock = d + " " + h + ":" + m + ":" + s;
    },
    lastTimeFilter(id, t) {
      if (id == 1) {
        if (t) {
          return new Date(t).toLocaleDateString().substr(5);
        }
      }
      if (id == 2) {
        if (t) {
          return t.split("-")[0];
        }
      }
      if (id == 3) {
        if (t) {
          return parseInt(t.substr(0, 2)) > 12 ? "pm" : "am";
        }
      }
      return "";
    },
    getStartTime(t) {
      if (t) {
        let st = t.split("-")[0];
        this.startHour = parseInt(t.split(":")[0]);
        return st;
      }
      return "";
    },
  },
  mounted() {
    this.setTimeVal();
    this.timeInterVal = setInterval(() => this.setTimeVal(), 1000);
  },
  destroyed() {
    this.timeInterVal = null;
  },
};
</script>
<style>
.weakness {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.test_again_cont {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 10px 0 50px 0;
}
.test_chart_cont {
  width: 100%;
  height: 500px;
  margin: 20px 0;
}
.adderFlex {
  display: flex !important;
  justify-content: space-around;
}
.stu_input_city {
  width: 25% !important;
}
.stu_input_adder {
  flex: 1 !important;
}
.address_title {
  font-size: 16px;
  margin: auto 4px;
}
.paginations {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.el-input__inner {
  border: 1px solid #9e9e9e !important;
  color: #757575;
}
.el-input__prefix {
  color: #757575;
}
.el-table tbody .cell {
  color: #000;
  font-size: 16px;
  text-align: center;
}
.el-table thead th {
  padding: 0;
  background: #000;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.subjectName{
    display: inline-block;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.subjectUrl{
  cursor: pointer;
  color: #018247;
}
.subjectUrl:hover {
  color: #ffd737 ;
}
.subjectUrl:visited {
  color: #ffd737 ;
}
</style>