<template>
  <div>
    <!-- 最上端黑色/绿色橫bar -->
    <top-vertical-nav></top-vertical-nav>
    <!-- 最上端黑色橫bar end -->

    <div class="member_main_area">
      <div class="member_main_container">
        <div class="member_nav_container">
          <div class="member_nav_logo">
            <a target="_blank" @click="goHome">
              <img src="../../assets/image/member_login_logo.png" alt />
            </a>
          </div>
          <div
            class="fb-customerchat"
            page_id="@093jpnhk"
            minimized="true"
          ></div>
          <div
            class="member_nav_btn_open"
            id="btnOpen"
            @click="toggleNavlist"
            role="button"
          >
            會員資訊
          </div>
          <div class="member_nav_account_container">
            <div class="member_nav_account">
              <div class="member_nav_headpic">
                <img :src="userAvatar" alt />
              </div>
              <div class="member_nav_infor">
                <div>
                  <span class="display_none_600">{{
                    studentInfo.studentName
                  }}</span>
                  [{{ studentInfo.mobile }}]
                  <span
                    style="float:right"
                    class="member_setting"
                    @click="memberSetting = !memberSetting"
                    >帳號管理</span
                  >
                </div>
                <div>
                  <span>
                    <span class="display_none_600">上課</span>點數：{{
                      studentInfo.tallyValue
                    }}點
                  </span>
                  <span style="float: right">
                    <span
                      class="member_point_deposit"
                      @click="showNewsDialog(5)"
                      >儲值</span
                    >
                    <img
                      src="../../assets/image/yellow_line.jpg"
                      class="yellow_line"
                      alt
                    />
                    <span class="member_point_record" @click="showNewsDialog(6)"
                      >記錄</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 會員資訊 -->
          <div class="member_nav_btn_container" id="showNavCont">
            <div class="member_nav_btn nav_btn_news" @click="showNewsDialog(1)">
              最新消息
              <div class="member_news_notice" v-if="isNewNoticeType"></div>
            </div>
            <div class="member_nav_btn nav_btn_rule" @click="showNewsDialog(2)">
              上課公約
            </div>
            <div
              class="member_nav_btn nav_btn_device"
              @click="showNewsDialog(3)"
            >
              設備檢測
            </div>
            <div class="member_nav_btn nav_btn_qa" @click="showNewsDialog(4)">
              常見問題
            </div>
          </div>
          <!-- 會員資訊end -->
          <!-- 帳號設定 -->
          <div class="member_setting_window" v-if="memberSetting">
            <div class="member_setting_container">
              <div class="member_setting_headpic">
                <img :src="userAvatar" alt />
              </div>
              <div class="member_setting_name">
                {{ studentInfo.studentName }}
              </div>
              <div class="member_setting_account">{{ studentInfo.mobile }}</div>
              <div
                class="member_setting_btn headpic_upload_open"
                @click="sendUserAvatar(5)"
              >
                上傳頭像
              </div>
              <div
                class="member_setting_btn reset_passowrd_btn"
                @click="updatePassword"
              >
                修改密碼
              </div>
              <div class="member_setting_btn" @click="loginOut">帳號登出</div>
              <div
                class="member_setting_window_close"
                @click="memberSetting = false"
              >
                關閉
              </div>
            </div>
          </div>
          <!-- 帳號設定end -->
        </div>
        <student-check-table :userId="studentInfo.userId"></student-check-table>
      </div>
    </div>
    <div class="system_talk_area" style>
      <img
        src="../../assets/image/system_talk_close.png"
        class="system_talk_close"
        alt
      />
      <div class="system_talk_window">
        <div
          class="fb-customerchat"
          attribution="setup_tool"
          page_id="103255404625425"
          logged_in_greeting="嗨~您好 請問需要幫助嗎?"
          logged_out_greeting="嗨~您好 請問需要幫助嗎?"
        ></div>
      </div>
    </div>
    <common-temp
      ref="commonNews"
      :topNavId.sync="topNavDialogId"
      :userInfo="noticeData"
      modalType="student"
    ></common-temp>
    <!-- ... pup視窗-點數紀錄 ... -->
    <div class="member_pup_point_record" v-if="topNavDialogId == 6">
      <div class="member_pup_bg" @click="topNavDialogId = 0"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">點數紀錄</div>
        <div class="member_pup_main">
          <div class="pup_news_search_container"></div>

          <div class="point_top_container">
            <div class="pup_point_record_search">
              <div class="block">
                <el-date-picker
                  v-model="pointSelectM"
                  @change="selectPointM"
                  type="month"
                  placeholder="選擇月份"
                ></el-date-picker>
              </div>
            </div>
            <div class="point_have_now">
              目前點數：
              <span>{{ studentInfo.tallyValue }}</span
              >&nbsp;點
            </div>
          </div>

          <div class="point_record_title_contianer">
            <div class="point_record_title_date">日期</div>
            <div class="point_record_title_type">類別</div>
            <div class="point_record_title_count">點數</div>
            <div class="point_record_title_memo">備註</div>
          </div>

          <div class="pup_point_main" ref="pupPointMain">
            <div
              class="point_record_contianer"
              v-for="(log, i) in pointLoglist"
              :key="i"
            >
              <div class="point_record_date">
                {{ log.createTime.split(" ")[0] }}
              </div>
              <div class="point_record_type">{{ log.itemTypeString }}</div>
              <div class="point_record_count">
                <span
                  :class="{
                    point_plus: log.changeType == 1,
                    point_minus: log.changeType == 2,
                  }"
                  >{{ log.changeType == 1 ? "+" : "-"
                  }}{{ log.tallValue }}</span
                >
              </div>
              <div class="point_record_memo">{{ log.remark }}</div>
            </div>
          </div>
          <!-- <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              :hide-on-single-page="pointPage.pages"
              @current-change="handleCurrentChange"
              :current-page="pointPage.count"
              :page-size="pointPage.size"
              layout="total, prev, pager, next, jumper"
              :total="pointPage.total"
            ></el-pagination>
          </div>-->
        </div>
        <img
          alt
          @click="closeDialog"
          class="member_pup_close"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-點數紀錄end ... -->

    <!-- ... pup視窗-儲值點數 ... -->
    <div class="buy_point_pup" v-if="topNavDialogId == 5">
      <div class="member_pup_bg" @click="topNavDialogId = 0"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">儲值點數</div>
        <div class="member_pup_main">
          <div class="buy_point_main">
            <div
              class="buy_item_container"
              v-for="(recharge, i) in rechargeList"
              :key="i"
            >
              <div class="buy_item_product">
                <div>
                  <div class="buy_item_product_pic">
                    <img alt src="../../assets/image/member_login_logo.png" />
                  </div>
                  <div class="buy_item_product_point">
                    {{ recharge["rechargePoint"] }}點
                  </div>
                </div>
              </div>
              <div class="buy_item_infor_container">
                <div class="buy_item_infor_title">
                  {{ recharge["shelfName"] }}
                </div>
                <div class="buy_item_infor_text">
                  {{ recharge["shelfRemark"] }}
                </div>
                <div class="buy_item_infor_price">
                  <span class="price_discount">
                    售價：
                    <span>NT${{ recharge["payForCash"] }}元</span>
                  </span>
                  <span class="price_original"
                    >原價：NT${{ recharge["originalPrice"] }}元</span
                  >
                </div>
                <div class="buy_item_infor_count">
                  <div class="buy_item_infor_count_title">數量：</div>
                  <div class="buy_item_infor_input">
                    <el-input-number
                      v-model="pointList[i]"
                      @change="setPoint(i)"
                      :min="0"
                    ></el-input-number>
                  </div>
                  <div class="buy_item_infor_money">
                    小計：{{ priceList[i] }}元
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buy_point_total_container">
            <div class="buy_item_line"></div>
            <div class="buy_item_discount_id">
              <input
                placeholder="輸入折扣代碼"
                v-model="initRecharge.couponNumber"
                type="text"
              />
              <div class="buy_item_discount_btn" @click="checkCoupon">
                使用折扣
              </div>
            </div>
            <div class="buy_item_discount_tip" v-if="couponError">
              折扣代碼有誤或已使用
            </div>
            <div class="buy_point_infor">
              <span>點數合計：{{ totalPoint }}點</span>
              <span>點數金額：{{ totalPrice }}元</span>
              <span v-if="discountCash" style="color: #0ba96c"
                >折扣金額：{{ discountCash }} 元</span
              >
            </div>
            <div class="buy_point_total">
              <span>應付金額：{{ payforPrice > 0 ? payforPrice : 0 }}元</span>
            </div>
            <a>
              <div class="buy_go_to_check" @click="surePayFor">確認結帳</div>
            </a>
          </div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="topNavDialogId = 0"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-儲值點數跳轉end ... -->
    <!-- ... 修改用戶信息 ... -->
    <change-user-info
      templateCode="5"
      :showStep="currDialogId"
      :avatar="userAvatar"
      @changeStep="sendUserAvatar"
    ></change-user-info>
  </div>
</template>
<script>
import { Message } from "element-ui";
import commonTemp from "../childComponent/common_news_temp";
import changeUserInfo from "../childComponent/change_user_info.vue";
import studentCheckTable from "../childComponent/student_check_table.vue";
import topVerticalNav from "../childComponent/topVerticalNav.vue";
import defaultImg from "@/assets/image/member_headpic_default.jpg";
import {
  getHsdUserInfo,
  getHsdLoginOut,
  getRechargeList,
  getCheckCoupon,
  getInitRecharge,
  getUserPointLog,
  getHsdNoticesType,
} from "../../api/api";
import { Calendar } from "element-ui";
export default {
  data() {
    return {
      isNewNoticeType: false,
      pointList: [], //储值点数
      priceList: [], //储值点数价格
      fileList: [],
      currDialogId: null,
      memberSetting: false, //账号设定
      classDetailNews: false, //上课详细咨询
      reportStar: false, //给予评分
      studentInfo: {},
      userAvatar: "",
      topNavDialogId: 0,
      messageTableId: 3,
      todays: [],
      currentMonthDays: { prev: [], days: 0, next: 0 }, //当前月的天数
      weeksList: ["日", "一", "二", "三", "四", "五", "六"],
      noticeData: { userId: "", noticeType: "1" },
      chNumber: [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
      ],
      rechargeList: [],
      couponNumber: "",
      couponError: false,
      couponErrorTips: "",
      totalPoint: 0, //总点数
      totalPrice: 0, //总金额
      discountCash: null, //折扣金额
      payforPrice: 0,
      initRecharge: {},
      pointLoglist: [],
      pointSelectM: "", //选择点数筛选月份
      pointPage: { pageSize: 10, pageNum: 0, total_page: 0 }, //点数分页
    };
  },
  components: { topVerticalNav, changeUserInfo, commonTemp, studentCheckTable },
  created() {
    // console.log(this.defaultImg);
    this.getUserInfo();
    this.getRechargeList();
    this.getPointLog();
    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: "v7.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  methods: {
    //返回首页
    goHome() {
      this.$router.push("/index");
    },
    //导航栏显示与隐藏
    toggleNavlist() {
      let content = document.getElementById("showNavCont"),
        btn = document.getElementById("btnOpen");
      let display;
      if (content.currentStyle) {
        display = content.currentStyle.display;
      } else {
        display = window.getComputedStyle(content, false).display;
      }
      if (display == "block") {
        content.classList.remove("nav-content");
        btn.classList.remove("active");
      } else {
        content.classList.add("nav-content");
        btn.classList.add("active");
      }
    },
    //点数变动日志
    async getPointLog() {
      let num = this.pointPage.pageNum;
      this.pointPage.pageNum = num + 1;
      const res = await getUserPointLog(this.pointPage);
      if (res && res.code == 200) {
        let list = res.data.data ? res.data.data : [];
        list = this.pointLoglist.concat(list);
        this.pointLoglist = list;
        this.pointPage.total_page = res.data.total_page;
        // console.log(this.pointLoglist.length);
      }
    },
    async getNoticeType() {
      const res = await getHsdNoticesType({
        noticeType: "1",
        userId: this.studentInfo.userId,
      });
      if (res && res.success == 1) {
        this.isNewNoticeType = res.data;
      }
    },
    //筛选月份
    selectPointM(date) {
      let month = date.getMonth() + 1;
      month = month > 9 ? month : `0${month}`;
      this.pointPage.LogMonth = `${date.getFullYear()}-${month}`;
      this.pointPage.pageNum = 0;
      this.pointLoglist = [];
      this.getPointLog();
    },
    //滚动监听
    pointScrollTo(e) {
      if (this.pointPage.total_page > this.pointPage.pageNum) {
        let scrollHeight = e.target.scrollHeight;
        let scrollTop = e.target.scrollTop;
        let clientHeight = e.target.clientHeight;
        if (scrollTop + clientHeight == scrollHeight) {
          this.getPointLog();
        }
      }
    },
    handleSizeChange(val) {
      this.pointPage.size = val;
    },
    handleCurrentChange(val) {
      this.pointPage.num = val;
    },
    //充值产品条目
    async getRechargeList() {
      const res = await getRechargeList();
      if (res.code == 200) {
        this.rechargeList = res.data;
        let len = this.rechargeList.length;
        for (let i = 0; i < len; i++) {
          this.pointList.push(0);
          this.priceList.push(0);
        }
      }
    },
    //增减点数
    setPoint(i) {
      this.priceList[i] = this.rechargeList[i].payForCash * this.pointList[i];
      this.totalPoint = this.rechargeList[i].rechargePoint * this.pointList[i];
      this.totalPrice = this.priceList[i];
      let count = this.discountCash ? this.discountCash : 0;
      this.payforPrice = this.totalPrice - count;
      for (let k = 0; k < this.pointList.length; k++) {
        if (k !== i) {
          this.pointList[k] = 0;
          this.priceList[k] = 0;
        }
      }
    },
    //检查优惠券
    async checkCoupon() {
      if (!this.initRecharge.couponNumber) {
        this.discountCash = null;
        this.payforPrice = this.totalPrice;
        return;
      }
      const res = await getCheckCoupon({
        couponNumber: this.initRecharge.couponNumber,
      });
      if (res.code == 200) {
        this.couponError = false;
        this.discountCash = res.data;
        this.payforPrice = this.totalPrice - this.discountCash;
      } else {
        this.couponError = true;
        this.discountCash = null;
        this.payforPrice = this.totalPrice;
      }
    },
    //确认支付初始化订单
    async surePayFor() {
      let point = this.pointList.filter((p) => p > 0)[0];
      if (point) {
        let i = this.pointList.indexOf(point);
        this.initRecharge.rechargeShelfId = this.rechargeList[i][
          "rechargeShelfId"
        ];
        this.initRecharge.quantity = this.pointList[i];
        let routeUrl = this.$router.resolve({
          path: "/payChecking",
          query: this.initRecharge,
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    //上传用户头像
    sendUserAvatar(param) {
      if (param.url) {
        this.currDialogId = param.id;
        this.userAvatar = param.url;
      } else this.currDialogId = param;
      this.memberSetting = false;
    },
    //打开修改密码弹窗
    updatePassword() {
      this.currDialogId = 1;
      this.memberSetting = false;
    },
    //我的课表日期格式过滤
    dateFliter(date) {
      let day = date.split("-")[2];
      if (parseInt(day) < 10) {
        day = parseInt(day);
      }
      return day;
    },
    //上课详细咨询
    getStudentDetail() {
      this.classDetailNews = true;
    },
    //学生评分
    getReportStar() {
      this.reportStar = true;
    },
    //我的课表切换
    showTable(id) {
      this.messageTableId = id;
    },
    //头部弹窗显示
    showNewsDialog(id) {
      this.topNavDialogId = id;
      if (id == 6) {
        let _this = this;
        this.$nextTick(() => {
          let dom = _this.$refs.pupPointMain;
          dom.addEventListener("scroll", _this.pointScrollTo);
        });
      }
      if (id == 1) {
        if (this.isNewNoticeType) {
          this.isNewNoticeType = false;
        }
        this.$refs["commonNews"].getNoticeList();
      }
    },
    closeDialog() {
      this.topNavDialogId = 0;
      let dom = this.$refs.pupPointMain;
      if (dom) dom.removeEventListener("scroll", this.pointScrollTo);
    },
    //获取用户基本信息
    async getUserInfo() {
      const res = await getHsdUserInfo();
      if (res && res.code == 200) {
        this.studentInfo = res.data;
        this.noticeData.userId = res.data.userId;
        if (res.data.photo) {
          this.studentInfo.photo = `data:image/png;base64,${res.data.photo}`;
          this.userAvatar = this.studentInfo.photo;
        }
        this.getNoticeType();
      }
    },
    //登出
    async loginOut() {
      this.memberSetting = false;
      const res = await getHsdLoginOut();
      if (res.code == 200) {
        localStorage.removeItem("token");
        this.$router.push("/login");
      }
    },
    //學生課表
    async getStudentClass(date) {
      console.log(date.getDate());
    },
  },
  mounted() {},
};
</script>
<style>
@import "../../assets/css/member_center.css";
@import "../../assets/css/clndr.css";

select {
  color: #767676 !important;
}
.is-selected {
  color: #1989fa;
}
.el-input-number {
  width: 100% !important;
}
.el-input-number__increase,
.el-input-number__decrease {
  background: #018247 !important;
  color: #ffffff !important;
  border-radius: 0;
}
.el-input__inner::-webkit-input-placeholder {
  color: #757575;
}
.pagination {
  margin-top: 20px;
  width: 100;
  display: flex;
  justify-content: center;
}
.member-container {
  display: block;
}
@media (max-width: 500px) {
  .member-container {
    display: block;
  }
}
@media (max-width: 800px) {
  .member_lesson_infor .tab_li {
    width: calc(20% - 10px);
    margin: 0 5px;
  }
}

/* .el-input__inner {
  border: 1px solid #202020 !important;
  color: #202020;
}
.el-input__prefix {
  color: #202020;
} */
/* .el-input__inner {
  border: 1px solid #0ba96c !important;
} */
.el-month-table td.current:not(.disabled) .cell {
  color: #0ba96c !important;
}
.nav-content {
  display: block;
}
@media (max-width: 500px) {
  .nav-content {
    display: block;
  }
}
</style>